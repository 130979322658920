@media only screen and ( max-width: map-get($grid-breakpoints, lg) ) {

	#not-found {
		.animate {
			bottom: 20px;
			&:nth-child(2) {
				// top: 20px;
			}
		}
	}

}

@media only screen and ( max-width: map-get($grid-breakpoints, md) ) {


	
}

@media only screen and ( max-width: map-get($grid-breakpoints, sm) ) {


	
}