$env: "production";
// Global
@import "../../base/global";

// Glide JS
@import "../../base/modules/glide/glide-core";
@import "../../base/modules/glide/glide-theme";

// Other Modules
@import "../../base/modules/content-animation";

#not-found {
	padding: 80px 0;
	position: relative;
	.animate {
		bottom: 40px;
		&:nth-child(2) {
			bottom: auto;
			left: auto;
			right: 0;
		}
	}
	img {
		margin-bottom: 40px;
	}
	h4 {
		line-height: 26px;
		margin-bottom: 12px;
		color: #202020;
		font-weight: 500;
	}
	p {
		line-height: 18px;
		margin-bottom: 32px;
		color: #202020;
	}
}

@import "./media";